import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from './Image';
import { decodeEntities } from '../utils/htmlParse';
import './SubCategoriesList.scss';
// Borrow styles from ProductList component
import './ProductList.scss';

const SubCategoriesList = ({ categories }) => {
  if (!categories) return null;
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          productsSlug
        }
      }
    }
  `);
  return (
    <div className="product-subcategories-list product-list">
      {categories
       .sort((a, b) => parseInt(a.node.acf.order) - parseInt(b.node.acf.order))
       .map(({node: cat}) => {
        const catLink = cat.link.replace(cat.taxonomy.slug, site.siteMetadata.productsSlug);
        return (
          <Link className="product-subcategory product-list-item" to={catLink} key={cat.link}>
            <Image className="product-list-item-image" image={cat.acf ? cat.acf.featureImage : null} />
            <span className="product-list-item-name">{decodeEntities(cat.name)}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default SubCategoriesList;
