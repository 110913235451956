import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './ProductCategoriesNavigation.scss';

const ProductCategoriesNavigation = ({ location, topLevelParentChildren }) => {
  const [openCategories, setOpenCategories] = useState([]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { site: { siteMetadata: { productsSlug } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            productsSlug
          }
        }
      }
    `
  );
  const currentCategorySlug = location.pathname.replace(`/${productsSlug}/`, '');
  useEffect(() => {
    const openCatIndex = topLevelParentChildren.findIndex(item => {
      const regex = new RegExp(`^.+/${item.slug}/`);
      return currentCategorySlug.match(regex);
    });
    if (openCatIndex !== -1) setOpenCategories([openCatIndex]);
  }, []);
  const isActive = (slug) => {
    return currentCategorySlug === slug;
  };
  const toggleOpenCategories = (idx) => {
    const openCategoriesIndex = openCategories.indexOf(idx);
    const openCategoriesClone = [...openCategories];
    if (openCategoriesIndex === -1) {
      openCategoriesClone.push(idx);
    } else {
      openCategoriesClone.splice(openCategoriesIndex, 1);
    }
    setOpenCategories(openCategoriesClone);
  }
  const productCategoriesNavClassnames = [
    `product-categories-navigation`,
    `${mobileNavOpen ? 'mobile-open' : ''}`,
    `${topLevelParentChildren.length === 0 ? 'empty' : ''}`
  ];
  return (
    <div className={productCategoriesNavClassnames.join(' ')}>
      {/* eslint-disable-next-line */}
      <span
        className="product-categories-navigation-heading"
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        Service Categories
      </span>
      {topLevelParentChildren.length > 0 && topLevelParentChildren
       .sort((a, b) => parseInt(a.acf.order) - parseInt(b.acf.order))
       .map((child, index) => {
        const subCategorySlug1 = child.link.replace(`/${child.taxonomy.slug}/`, '');
        const hasChildren = child.thirdLevelCategories;
        const subcatlv1classNames = [
          'sub-category--lv1',
          `${openCategories.indexOf(index) >= 0 ? 'open' : ''}`,
          `${hasChildren ? 'has-children' : ''}`
        ];
        return (
          // eslint-disable-next-line
          <div
            className={subcatlv1classNames.join(' ')}
            onClick={() => toggleOpenCategories(index)}
            key={child.link}
          >
            <Link
              to={`/${productsSlug}/${subCategorySlug1}`}
              className={isActive(subCategorySlug1) ? 'active' : ''}
            >
              {decodeEntities(child.name)}
            </Link>
            {hasChildren && (
              <div className="sub-category--lv2">
                {child.thirdLevelCategories.map(({node: lowerChild}) => {
                  const subCategorySlug2 = lowerChild.link.replace(`/${lowerChild.taxonomy.slug}/`, '');
                  return (
                    <Link
                      to={`/${productsSlug}/${subCategorySlug2}`}
                      className={isActive(subCategorySlug2) ? 'active' : ''}
                      key={lowerChild.link}
                    >
                      {decodeEntities(lowerChild.name)}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProductCategoriesNavigation;
