import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './ProductCategoryBreadcrumbs.scss';

const Breadcrumbs = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          productsSlug
        }
      }
      allWordpressWpServicesCat {
        nodes {
          name
          slug
          link
          taxonomy {
            slug
          }
        }
      }
    }
  `);
  const { allWordpressWpServicesCat, site } = data;
  const { siteMetadata: { productsSlug } } = site;
  const catHierarchyElements = location.pathname
    .replace(`/${productsSlug}/`, '')
    .split('/')
    .filter(el => el !== '') // trailing slash creates empty split element
    .map(el => allWordpressWpServicesCat.nodes.find(cat => cat.slug === el));
  return (
    <div className="product-category-breadcrumbs breadcrumbs">
      <div className="crumb">
        <Link to="/">Home</Link>
      </div>
      {catHierarchyElements && catHierarchyElements !== undefined && catHierarchyElements.map(cat => {
        const catLink = cat?.link?.replace(cat?.taxonomy?.slug, productsSlug);
        return (
          <div className="crumb" key={cat?.slug}>
            <Link to={catLink}>{decodeEntities(cat.name)}</Link>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
